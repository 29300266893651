var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import borders from '../helpers/svgHeroBorders';
import { HERO_ITEM_BASE_MARGIN } from './HeroItemMainPage';
export var HeroItem = function (_a) {
    var img = _a.img, text = _a.text, name = _a.name, subtitle = _a.subtitle, _b = _a.moviePage, moviePage = _b === void 0 ? false : _b;
    var ref = React.createRef();
    var _c = __read(React.useState(HERO_ITEM_BASE_MARGIN), 2), marginBottom = _c[0], setMarginBottom = _c[1];
    var _d = __read(React.useState(0), 2), textHeight = _d[0], setTextHeight = _d[1];
    var _e = __read(React.useState(), 2), border = _e[0], setBorder = _e[1];
    React.useEffect(function () {
        var keys = Object.keys(borders);
        setBorder(borders[keys[(keys.length * Math.random()) << 0]]);
    }, []);
    React.useEffect(function () {
        if (ref.current) {
            setTextHeight(ref.current.offsetHeight - 60);
            setMarginBottom(ref.current.offsetHeight - 30);
        }
    }, [ref]);
    return (React.createElement("span", { className: "layout__item", style: {
            marginBottom: (moviePage ? 0 : marginBottom) + "px",
        } },
        !moviePage && (React.createElement("span", { className: "layout__item-bg", style: {
                backgroundImage: "url(" + border + ")",
                backgroundRepeat: 'no-repeat',
            } })),
        !moviePage && React.createElement(LazyLoadImage, { className: "layout__item-img", alt: img, src: img }),
        moviePage && React.createElement("img", { className: "layout__item-img", alt: img, src: img }),
        React.createElement("div", { className: "layout__item-text", ref: ref, style: { bottom: "-" + (moviePage ? marginBottom + 20 : textHeight) + "px" } },
            React.createElement("h3", { className: "hero__item--title" }, name),
            !!subtitle && React.createElement("h3", { className: "hero__item--subtitle" }, subtitle),
            React.createElement("p", null, text))));
};
