import React from 'react';
import { Col } from 'react-flexbox-grid';
export var AdditionalContentRow = function (_a) {
    var question = _a.question, answer = _a.answer, image = _a.image;
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { xs: 12, mdOffset: 1, md: 3 },
            image && React.createElement("img", { src: image, className: "additional-content__image" }),
            React.createElement("p", null,
                React.createElement("strong", null, question))),
        React.createElement(Col, { xs: 12, mdOffset: 1, md: 7 }, answer.map(function (a, index) {
            if (a.type === 'paragraphText') {
                return React.createElement("p", { key: index }, a.content);
            }
            else if (a.type === 'paragraphImg') {
                return React.createElement("img", { key: index, src: a.content, className: "additional-content__image" });
            }
            else {
                return React.createElement("h4", { key: index }, a.content);
            }
        }))));
};
