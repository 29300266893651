export var getRandomBorder = function (max) {
    var num = Math.floor(Math.random() * max) + 1;
    return ("" + num).length > 1 ? "" + num : "0" + num;
};
export var prepareArrForRows = function (arr, size) {
    return arr.reduce(function (carry, _, index, orig) { return (!(index % size) ? carry.concat([orig.slice(index, index + size)]) : carry); }, []);
};
export var getOffset = function () {
    var a = 0;
    var arr = [];
    while (a < 4) {
        if (arr.reduce(function (prevItem, item) { return prevItem + item; }, 0) < 3) {
            arr.push(Math.floor(Math.random() * 3));
        }
        else if (arr.reduce(function (prevItem, item) { return prevItem + item; }, 0) < 4) {
            arr.push(1);
        }
        else {
            arr.push(0);
        }
        a++;
    }
    return arr;
};
