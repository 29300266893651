import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { AdditionalContentRow } from '../AdditionalContentRow';
import { PageTitle } from '../PageTitle';
import { ShareSocial } from '../ShareSocial';
export var AdditionalContentPiece = function (_a) {
    var title = _a.title, intro = _a.intro, introImg = _a.introImg, questions = _a.questions, url = _a.url;
    var canonicalUrl = "https://zona.currenttime.tv" + url;
    return (React.createElement(Grid, { fluid: true, className: "layout" },
        React.createElement(Helmet, null,
            React.createElement("link", { href: canonicalUrl, rel: "canonical" })),
        React.createElement("div", { className: "container-fluid__paddings" },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, mdOffset: 5, md: 7, className: "title__bg-image title__bg-image--add-content" },
                    React.createElement("img", { src: introImg })),
                React.createElement(Col, { xs: 6, className: "title__main title__main--add-content" },
                    React.createElement(PageTitle, { secondTitleLine: title, small: true }, title))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 7, className: "title__intro" }, intro.map(function (i, index) { return (React.createElement("p", { key: index }, i)); })),
                React.createElement(Col, { xs: 12, mdOffset: 1, md: 4, className: "title__share title__share--no-margin" },
                    React.createElement(ShareSocial, null))),
            React.createElement("div", { className: "additional-content__content" }, questions.map(function (_a, index) {
                var question = _a.question, answer = _a.answer, image = _a.image;
                return (React.createElement(Row, { key: index },
                    React.createElement(AdditionalContentRow, { question: question, answer: answer, image: image })));
            })))));
};
