var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Navigation } from './Navigation';
export var Header = function () {
    var location = useLocation();
    var _a = __read(useState(false), 2), utagReady = _a[0], setUtagReady = _a[1];
    React.useEffect(function () {
        var int = setInterval(function () {
            if (window.utag) {
                setUtagReady(true);
                clearInterval(int);
            }
        }, 100);
        return function () {
            clearInterval(int);
        };
    }, []);
    React.useEffect(function () {
        if (!window.utag) {
            return;
        }
        window.utag.view({
            "page": document.URL,
            "dom.url": document.URL,
            "dom.domain": document.location.hostname,
            "dom.pathname": document.location.pathname,
            "dom.query_string": document.location.search,
            "dom.referrer": document.referrer,
            "dom.title": document.title
        });
    }, [utagReady, location]);
    return React.createElement(Navigation, null);
};
