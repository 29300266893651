var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import specialBorderAnimation from '@assets/img/vector/borders/homepage-intro-movie-border.svg';
import specialBorderNoAnimation from '@assets/img/vector/borders/homepage-intro-movie-border-no-animation.svg';
import loopVideo from '@assets/video/loop-video-main-page.mp4';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { CarouselCmp } from '../Carousel';
import { PageTitle } from '../PageTitle';
export var Home = function () {
    var _a = __read(useState(false), 2), stopAnimation = _a[0], setStopAnimation = _a[1];
    var specialBorderOnHover = function () {
        setStopAnimation(!stopAnimation);
    };
    return (React.createElement(Grid, { fluid: true, className: "layout" },
        React.createElement(Helmet, null,
            React.createElement("link", { href: "https://zona.currenttime.tv", rel: "canonical" })),
        React.createElement("div", { className: "container-fluid__paddings" },
            React.createElement(Row, null,
                React.createElement(Col, { xsOffset: 2, xs: 10, mdOffset: 4, md: 8, className: "title__bg-video" },
                    React.createElement("div", { className: "loop-video__container" },
                        React.createElement("video", { autoPlay: true, muted: true, loop: true, id: "loop-video" },
                            React.createElement("source", { src: loopVideo, type: "video/mp4" })))),
                React.createElement(Col, { xs: 12, md: 9, className: "title__main" },
                    React.createElement(PageTitle, { firstTitleLine: "\u0410\u0417\u0411\u0423\u041A\u0410", secondTitleLine: "\u0422\u042E\u0420\u042C\u041C\u042B" }))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, lgOffset: 1, lg: 7, className: "title__text title__text--main-page" },
                    React.createElement("p", null,
                        React.createElement("strong", null, "50 \u0438\u043D\u0442\u0435\u0440\u0432\u044C\u044E")),
                    React.createElement("p", null,
                        React.createElement("strong", null, "320 \u0447\u0430\u0441\u043E\u0432"),
                        " \u0432\u0438\u0434\u0435\u043E\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u043E\u0432"),
                    React.createElement("p", { style: { paddingLeft: 30 } },
                        React.createElement("strong", null, "256 \u043B\u0435\u0442 \u0438 181 \u0434\u0435\u043D\u044C \u043B\u0438\u0448\u0435\u043D\u0438\u044F \u0441\u0432\u043E\u0431\u043E\u0434\u044B \u0441\u0443\u043C\u043C\u0430\u0440\u043D\u043E")),
                    React.createElement("p", null,
                        React.createElement("strong", null, "\u0432 12"),
                        " ",
                        React.createElement("em", null, "\u0441\u0435\u0440\u0438\u044F\u0445"),
                        " \u0438 \u043E\u0434\u043D\u043E\u043C",
                        ' ',
                        React.createElement("span", { onMouseEnter: specialBorderOnHover, onMouseLeave: specialBorderOnHover, id: "main-page-intro-special", style: {
                                borderImage: "url(" + (stopAnimation ? specialBorderNoAnimation : specialBorderAnimation) + ") 20 / 20px / 3px stretch",
                            } },
                            React.createElement(Link, { to: "/sakharovo" }, "\u0444\u0438\u043B\u044C\u043C\u0435"))),
                    React.createElement("p", null,
                        React.createElement("strong", null, "\u0422\u044E\u0440\u044C\u043C\u0430 \u043E\u0442 \u0410 \u0434\u043E \u042F")),
                    React.createElement("p", { className: "title__text--main-page large-offset" },
                        React.createElement("strong", null, "\u0427\u0443\u0436\u043E\u0439 \u043E\u043F\u044B\u0442 \u043C\u043E\u0436\u0435\u0442 \u0441\u0442\u0430\u0442\u044C \u0442\u0432\u043E\u0438\u043C")))),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(CarouselCmp, null))));
};
