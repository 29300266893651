import heroBg01 from '@assets/img/vector/borders/heroes_border_01.svg';
import heroBg02 from '@assets/img/vector/borders/heroes_border_02.svg';
import heroBg03 from '@assets/img/vector/borders/heroes_border_03.svg';
import heroBg04 from '@assets/img/vector/borders/heroes_border_04.svg';
import heroBg05 from '@assets/img/vector/borders/heroes_border_05.svg';
import heroBg06 from '@assets/img/vector/borders/heroes_border_06.svg';
import heroBg07 from '@assets/img/vector/borders/heroes_border_07.svg';
import heroBg08 from '@assets/img/vector/borders/heroes_border_08.svg';
import heroBg09 from '@assets/img/vector/borders/heroes_border_09.svg';
import heroBg10 from '@assets/img/vector/borders/heroes_border_10.svg';
import heroBg11 from '@assets/img/vector/borders/heroes_border_11.svg';
import heroBg12 from '@assets/img/vector/borders/heroes_border_12.svg';
import heroBg13 from '@assets/img/vector/borders/heroes_border_13.svg';
import heroBg14 from '@assets/img/vector/borders/heroes_border_14.svg';
import heroBg15 from '@assets/img/vector/borders/heroes_border_15.svg';
import heroBg16 from '@assets/img/vector/borders/heroes_border_16.svg';
import heroBg17 from '@assets/img/vector/borders/heroes_border_17.svg';
import heroBg18 from '@assets/img/vector/borders/heroes_border_18.svg';
import heroBg19 from '@assets/img/vector/borders/heroes_border_19.svg';
import heroBg20 from '@assets/img/vector/borders/heroes_border_20.svg';
import heroBg21 from '@assets/img/vector/borders/heroes_border_21.svg';
import heroBg22 from '@assets/img/vector/borders/heroes_border_22.svg';
import heroBg23 from '@assets/img/vector/borders/heroes_border_23.svg';
import heroBg24 from '@assets/img/vector/borders/heroes_border_24.svg';
import heroBg25 from '@assets/img/vector/borders/heroes_border_25.svg';
import heroBg26 from '@assets/img/vector/borders/heroes_border_26.svg';
import heroBg27 from '@assets/img/vector/borders/heroes_border_27.svg';
import heroBg28 from '@assets/img/vector/borders/heroes_border_28.svg';
import heroBg29 from '@assets/img/vector/borders/heroes_border_29.svg';
import heroBg30 from '@assets/img/vector/borders/heroes_border_30.svg';
import heroBg31 from '@assets/img/vector/borders/heroes_border_31.svg';
var borders = {
    heroBg01: heroBg01,
    heroBg02: heroBg02,
    heroBg03: heroBg03,
    heroBg04: heroBg04,
    heroBg05: heroBg05,
    heroBg06: heroBg06,
    heroBg07: heroBg07,
    heroBg08: heroBg08,
    heroBg09: heroBg09,
    heroBg10: heroBg10,
    heroBg11: heroBg11,
    heroBg12: heroBg12,
    heroBg13: heroBg13,
    heroBg14: heroBg14,
    heroBg15: heroBg15,
    heroBg16: heroBg16,
    heroBg17: heroBg17,
    heroBg18: heroBg18,
    heroBg19: heroBg19,
    heroBg20: heroBg20,
    heroBg21: heroBg21,
    heroBg22: heroBg22,
    heroBg23: heroBg23,
    heroBg24: heroBg24,
    heroBg25: heroBg25,
    heroBg26: heroBg26,
    heroBg27: heroBg27,
    heroBg28: heroBg28,
    heroBg29: heroBg29,
    heroBg30: heroBg30,
    heroBg31: heroBg31,
};
export default borders;
