var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// @ts-ignore
import circleEpisode from '@assets/img/vector/other/circle_episode_number.svg';
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Col, Row } from 'react-flexbox-grid';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
export var CarouselTitle = function (_a) {
    var children = _a.children, episode = _a.episode, next = _a.next, previous = _a.previous, goToSlide = _a.goToSlide, activeEpisode = _a.activeEpisode, numberOfSlides = _a.numberOfSlides, _b = _a.arrows, arrows = _b === void 0 ? false : _b, _c = _a.marquee, marquee = _c === void 0 ? false : _c, _d = _a.toEdges, toEdges = _d === void 0 ? false : _d, rest = __rest(_a, ["children", "episode", "next", "previous", "goToSlide", "activeEpisode", "numberOfSlides", "arrows", "marquee", "toEdges"]);
    var _e = __read(React.useState(false), 2), tooltipOpened = _e[0], setTooltipOpened = _e[1];
    var currentSlide = rest.carouselState.currentSlide;
    React.useEffect(function () {
        if (activeEpisode && numberOfSlides && activeEpisode <= numberOfSlides) {
            goToSlide(activeEpisode - 1);
        }
    }, []);
    var classes = "carousel__title-wrapper" + (toEdges ? ' carousel__title-wrapper--fill' : '');
    var tooltipClasses = "carousel__arrow-tooltip" + (tooltipOpened ? ' display-block' : '');
    var clickArrowNext = function () {
        if (currentSlide + 1 !== numberOfSlides) {
            setTooltipOpened(false);
            next();
        }
        else {
            toggleTooltip();
        }
    };
    var clickArrowPrevious = React.useCallback(function () {
        setTooltipOpened(false);
        previous();
    }, []);
    var toggleTooltip = function () {
        setTooltipOpened(!tooltipOpened);
    };
    return (React.createElement("div", { className: classes },
        React.createElement(Row, null,
            React.createElement(Col, { xs: 1, lg: 1 },
                !!arrows && (React.createElement("span", { className: currentSlide > 0 ? '' : 'arrow--disabled' },
                    React.createElement(ArrowLeft, { onClick: clickArrowPrevious }))),
                !!arrows && (React.createElement("span", { className: tooltipClasses },
                    React.createElement("p", null, "\u0421\u043Be\u0434\u0443\u044E\u0449\u0430\u044F \u0441\u0435\u0440\u0438\u044F \u0431\u0443\u0434\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430 23.07.2021")))),
            !marquee ? (React.createElement(Col, { xs: arrows ? 9 : 12, mdOffset: 1, md: 7, lgOffset: 1, lg: 4 },
                React.createElement("h3", { className: "carousel__title" },
                    children,
                    React.createElement("span", { className: "carousel__title-number-wrapper" },
                        React.createElement("span", { className: "carousel__title-circle", dangerouslySetInnerHTML: { __html: circleEpisode } }),
                        React.createElement("sup", { className: "carousel__title-number" }, episode))))) : (React.createElement(Marquee, { pauseOnClick: true, gradient: false, className: "carousel__title" }, children)),
            React.createElement(Col, { xs: 1, lgOffset: 3, lg: 1 }, !!arrows && (React.createElement("span", { className: currentSlide + 1 !== numberOfSlides ? '' : 'arrow--disabled' },
                React.createElement(ArrowRight, { onClick: clickArrowNext })))))));
};
