import playerBorder01 from '@assets/img/vector/other/player_border_01.svg';
import playerBorder02 from '@assets/img/vector/other/player_border_02.svg';
import playerBorder03 from '@assets/img/vector/other/player_border_03.svg';
import playerBorder04 from '@assets/img/vector/other/player_border_04.svg';
import playerBorder05 from '@assets/img/vector/other/player_border_05.svg';
var playerBorders = {
    playerBorder01: playerBorder01,
    playerBorder02: playerBorder02,
    playerBorder03: playerBorder03,
    playerBorder04: playerBorder04,
    playerBorder05: playerBorder05,
};
export default playerBorders;
