import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
export var AlphabetItem = function (_a) {
    var alphabetWords = _a.alphabetWords, src = _a.src;
    return (React.createElement(Row, { className: "layout__row" },
        React.createElement(Col, { xs: 12, lgOffset: 1, lg: 2 },
            React.createElement("img", { src: src, className: "alphabet alphabet__image" })),
        React.createElement(Col, { xs: 12, lg: 4 }, alphabetWords.slice(0, alphabetWords.length / 2).map(function (_a, index) {
            var name = _a.name, content = _a.content;
            return (React.createElement("p", { key: name + "-" + index },
                React.createElement("strong", null, name),
                " ",
                content ? "\u2013 " + content : ''));
        })),
        React.createElement(Col, { xs: 12, lg: 4 }, alphabetWords.slice(alphabetWords.length / 2, alphabetWords.length).map(function (_a, index) {
            var name = _a.name, content = _a.content;
            return (React.createElement("p", { key: name + "-" + index },
                React.createElement("strong", null, name),
                " ",
                content ? "\u2013 " + content : ''));
        }))));
};
