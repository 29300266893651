// @ts-ignore
import arrow from '@assets/img/vector/other/arrow.svg';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export var AdditionalContentItem = function (_a) {
    var src = _a.src, title = _a.title, text = _a.text, link = _a.link, _b = _a.mainPage, mainPage = _b === void 0 ? false : _b;
    var classes = "add-content__wrapper" + (mainPage ? ' add-content__wrapper--main' : '');
    return (React.createElement("div", { className: classes },
        React.createElement(LazyLoadImage, { className: "add-content__image", alt: src, src: src }),
        React.createElement("p", { className: "add-content__title" }, title),
        React.createElement("p", { className: "add-content__text" }, text),
        React.createElement("a", { href: link, className: "add-content__link" },
            React.createElement("span", { dangerouslySetInnerHTML: { __html: arrow } }))));
};
