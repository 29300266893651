var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import 'react-multi-carousel/lib/styles.css';
import additionalContent from '@assets/json/additionalContentMainPage.json';
import alphabet from '@assets/json/alphabetMainPage.json';
import episodes from '@assets/json/episodes.json';
import heroes from '@assets/json/heroes.json';
import React from 'react';
import Carousel from 'react-multi-carousel';
// @ts-ignore
import { CarouselTitle } from './CarouselTitle';
import { PageCarousel } from './PageCarousel';
import { VideoPoster } from './VideoPoster';
var responsiveEpisodes = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        partialVisibilityGutter: 300,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        partialVisibilityGutter: 120,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 40,
    },
};
export var CarouselCmp = function () {
    var _a = __read(React.useState(1), 2), visibleEpisode = _a[0], setVisibleEpisode = _a[1];
    var _b = __read(React.useState(true), 2), allowVideosPlay = _b[0], setAllowVideosPlay = _b[1];
    var moveSliders = React.useCallback(function (nextSlide, _a) {
        var currentSlide = _a.currentSlide, rest = __rest(_a, ["currentSlide"]);
        setAllowVideosPlay(false);
        setVisibleEpisode(nextSlide + 1);
    }, []);
    var videosPlaybackOff = React.useCallback(function () {
        setAllowVideosPlay(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "carousel__wrapper" },
            React.createElement(Carousel, { beforeChange: moveSliders, afterChange: videosPlaybackOff, arrows: false, responsive: responsiveEpisodes, keyBoardControl: true, renderButtonGroupOutside: true, draggable: true, partialVisbile: true, renderArrowsWhenDisabled: true, customButtonGroup: React.createElement(CarouselTitle, { arrows: true, activeEpisode: 1, episode: "" + visibleEpisode, numberOfSlides: episodes.length }, "\u0441\u0435\u0440\u0438\u044F") }, episodes.map(function (ep, index) { return (React.createElement(VideoPoster, { src: ep.videoId, videoIntro: ep.videoIntro, videoPoster: ep.videoPoster, key: index, allowVideosPlay: allowVideosPlay })); }))),
        React.createElement(PageCarousel, { heroesData: heroes, additionalContentData: additionalContent, alphabetData: alphabet, numberOfEpisodes: 2, visibleEpisode: visibleEpisode })));
};
