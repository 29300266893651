import React from 'react';
import { Col } from 'react-flexbox-grid';
export var Author = function (_a) {
    var src = _a.src, text = _a.text, name = _a.name, subtitle = _a.subtitle;
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { md: 4, lg: 2 },
            React.createElement("img", { src: src, style: { width: '100%' } })),
        React.createElement(Col, { md: 8, lg: 4 },
            React.createElement("div", { className: "author__text-wrapper" },
                React.createElement("h3", null, name),
                !!subtitle && React.createElement("h4", null, subtitle),
                React.createElement("p", null, text)))));
};
