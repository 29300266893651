var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// @ts-ignore
import bgImage from '@assets/img/vector/other/player_border_01.svg';
// @ts-ignore
import alphabetMainPage from '@assets/json/alphabetMainPage.json';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
// @ts-ignore
import close from '../../assets/img/vector/other/close_popup.svg';
export var Modal = function (_a) {
    var activeModal = _a.activeModal, handleClose = _a.handleClose, show = _a.show;
    var _b = __read(React.useState(), 2), modalContent = _b[0], setModalContent = _b[1];
    var wrapperRef = React.createRef();
    React.useEffect(function () {
        alphabetMainPage.forEach(function (alphabetArr) {
            return alphabetArr.forEach(function (item) {
                if (item.meaning === activeModal) {
                    setModalContent(item);
                    return;
                }
            });
        });
    }, [show]);
    return show ? (React.createElement("div", { onClick: handleClose, className: "modal", ref: wrapperRef },
        React.createElement(Row, { className: "modal__row" },
            React.createElement(Col, { mdOffset: 3, md: 6 },
                React.createElement("section", { onClick: function (e) { return e.stopPropagation(); }, className: "modal__main", style: { backgroundImage: "url(" + bgImage + ")" } },
                    React.createElement("span", { className: "modal__main-bg", dangerouslySetInnerHTML: { __html: bgImage } }),
                    React.createElement("img", { src: modalContent.link }),
                    React.createElement("p", null, modalContent.meaning),
                    React.createElement("span", { className: "modal__button", onClick: handleClose, dangerouslySetInnerHTML: { __html: close } })))))) : null;
};
