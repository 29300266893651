var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import borders from '../helpers/svgHeroBorders';
export var HERO_ITEM_BASE_MARGIN = 6;
export var HeroItemMainPage = function (_a) {
    var img = _a.img, text = _a.text, name = _a.name, _b = _a.setMargin, setMargin = _b === void 0 ? false : _b;
    var ref = React.createRef();
    var _c = __read(React.useState(0), 2), textHeight = _c[0], setTextHeight = _c[1];
    var _d = __read(React.useState(HERO_ITEM_BASE_MARGIN), 2), marginBottom = _d[0], setMarginBottom = _d[1];
    var _e = __read(React.useState(0), 2), textBottom = _e[0], setTextBottom = _e[1];
    var _f = __read(React.useState(0), 2), textVisible = _f[0], setTextVisible = _f[1];
    var _g = __read(React.useState(), 2), border = _g[0], setBorder = _g[1];
    React.useEffect(function () {
        var keys = Object.keys(borders);
        setBorder(borders[keys[(keys.length * Math.random()) << 0]]);
    }, []);
    React.useEffect(function () {
        if (ref.current) {
            setTextHeight(ref.current.offsetHeight);
        }
    }, [ref]);
    var handleClick = function () {
        if (!textVisible) {
            setMargin && setMarginBottom(textHeight);
            setTextBottom(textHeight);
            setTextVisible(1);
        }
        else {
            setMarginBottom(HERO_ITEM_BASE_MARGIN);
            setTextBottom(0);
            setTextVisible(0);
        }
    };
    return (React.createElement("span", { className: "layout__item layout__item--clickable", onClick: handleClick, style: {
            marginBottom: marginBottom,
        } },
        React.createElement("div", { className: "layout__item-img-wrapper" },
            React.createElement("span", { className: "layout__item-bg", style: {
                    backgroundImage: "url(" + border + ")",
                    backgroundRepeat: 'no-repeat'
                } }),
            React.createElement(LazyLoadImage, { alt: img, src: img, className: "layout__item-img" }),
            React.createElement("div", { className: "layout__item-text", ref: ref, style: { bottom: "-" + textBottom + "px", opacity: textVisible } },
                React.createElement("h3", null, name),
                React.createElement("p", null, text)))));
};
