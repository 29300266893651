import titleBgGigSrc from '@assets/img/vector/other/animation_header_heroes.gif';
import heroes from '@assets/json/heroesAll.json';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { prepareArrForRows } from '../../helpers/helpers';
import { HeroItem } from '../HeroItem';
import { PageTitle } from '../PageTitle';
import { ShareSocial } from '../ShareSocial';
export var Heroes = function () {
    return (React.createElement("div", null,
        React.createElement(Grid, { fluid: true, className: "layout" },
            React.createElement(Helmet, null,
                React.createElement("link", { href: "https://zona.currenttime.tv/heroes", rel: "canonical" })),
            React.createElement("div", { className: "container-fluid__paddings" },
                React.createElement(Row, null,
                    React.createElement(Col, { xsOffset: 3, xs: 8, mdOffset: 3, md: 5, className: "title__bg-image" },
                        React.createElement("img", { src: titleBgGigSrc })),
                    React.createElement(Col, { xs: 6, className: "title__main" },
                        React.createElement(PageTitle, { secondTitleLine: "\u0413\u0415\u0420\u041E\u0418" })),
                    React.createElement(Col, { xs: 12, mdOffset: 5, md: 7, lgOffset: 8, lg: 4, className: "title__share" },
                        React.createElement(ShareSocial, null))),
                prepareArrForRows(heroes.flat(), 4).map(function (array, index) { return (React.createElement(Row, { key: index }, array.map(function (hero, idx) {
                    return (React.createElement(Col, { xsOffset: 0, mdOffset: 0, lgOffset: 1, xs: 12, lg: 2, md: 6, key: idx },
                        React.createElement("div", { className: "hero__item-wrapper" },
                            React.createElement(HeroItem, { img: hero.heroSrc, text: hero.heroText, name: hero.heroName }))));
                }))); })))));
};
