var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import burger from '../../assets/img/vector/other/burger.svg';
import burgerLight from '../../assets/img/vector/other/burger-light.svg';
// @ts-ignore
import closeMenu from '../../assets/img/vector/other/close_button_menu.svg';
// @ts-ignore
import logo from '../../assets/img/vector/other/logo.svg';
// @ts-ignore
import logoLight from '../../assets/img/vector/other/logo-light.svg';
export var Navigation = function () {
    var _a = __read(React.useState(false), 2), show = _a[0], setShow = _a[1];
    var location = useLocation();
    var _b = __read(React.useState(location.pathname === '/sakharovo' ? true : false), 2), lightTheme = _b[0], setLightTheme = _b[1];
    var _c = __read(React.useState(burger), 2), menuIcon = _c[0], setMenuIcon = _c[1];
    var _d = __read(React.useState(burgerLight), 2), lightMenuIcon = _d[0], setLightMenuIcon = _d[1];
    var _e = __read(React.useState(location.pathname || '/'), 2), activePage = _e[0], setActivePage = _e[1];
    var showHideClassName = show ? 'modal display-block' : 'modal display-none';
    React.useEffect(function () {
        setActivePage(location.pathname);
        if (location.pathname === '/sakharovo') {
            setLightTheme(true);
        }
        else {
            setLightTheme(false);
        }
    }, [location.pathname]);
    React.useEffect(function () {
        if (show) {
            setMenuIcon(closeMenu);
            setLightMenuIcon(closeMenu);
        }
        else {
            setMenuIcon(burger);
            setLightMenuIcon(burgerLight);
        }
    }, [show]);
    var toggleOpen = function () {
        setShow(!show);
    };
    var closeMobileMenu = function () {
        setShow(false);
    };
    var navItemClass = function (path) { return "navigation__list-item " + (activePage === path ? 'isActive' : ''); };
    return (React.createElement("nav", { className: "navigation" },
        React.createElement("div", { className: "navigation__logo" },
            React.createElement("a", { href: "https://www.currenttime.tv" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: lightTheme ? logoLight : logo } }))),
        React.createElement("div", { className: "navigation__grow" }),
        React.createElement("ul", { className: "navigation__list--desktop" },
            React.createElement("li", { className: navItemClass('/') },
                React.createElement(Link, { to: "/" }, "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E")),
            React.createElement("li", { className: navItemClass('/sakharovo') },
                React.createElement(Link, { to: "/sakharovo" }, "\u0421\u0410\u0425\u0410\u0420\u041E\u0412\u041E.\u0424\u0418\u041B\u042C\u041C")),
            React.createElement("li", { className: navItemClass('/heroes') },
                React.createElement(Link, { to: "/heroes" }, "\u0413\u0415\u0420\u041E\u0418")),
            React.createElement("li", { className: navItemClass('/alphabet') },
                React.createElement(Link, { to: "/alphabet" }, "\u0410\u041B\u0424\u0410\u0412\u0418\u0422")),
            React.createElement("li", { className: navItemClass('/additional-content') },
                React.createElement(Link, { to: "/additional-content" }, "\u0434\u043E\u043F.\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B")),
            React.createElement("li", { className: navItemClass('/about') },
                React.createElement(Link, { to: "/about" }, "\u0410\u0412\u0422\u041E\u0420\u042B/\u041E \u041F\u0420\u041E\u0415\u041A\u0422\u0415"))),
        React.createElement("div", { className: "navigation__list--mobile", onClick: toggleOpen }, lightTheme ? (React.createElement("span", { dangerouslySetInnerHTML: { __html: lightMenuIcon } })) : (React.createElement("span", { dangerouslySetInnerHTML: { __html: menuIcon } }))),
        React.createElement("div", { className: showHideClassName },
            React.createElement("ul", { className: "menu" },
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/') },
                    React.createElement(Link, { to: "/" }, "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E")),
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/sakharovo') },
                    React.createElement(Link, { to: "/sakharovo" }, "\u0421\u0410\u0425\u0410\u0420\u041E\u0412\u041E.\u0424\u0418\u041B\u042C\u041C")),
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/heroes') },
                    React.createElement(Link, { to: "/heroes" }, "\u0413\u0415\u0420\u041E\u0418")),
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/alphabet') },
                    React.createElement(Link, { to: "/alphabet" }, "\u0410\u041B\u0424\u0410\u0412\u0418\u0422")),
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/additional-content') },
                    React.createElement(Link, { to: "/additional-content" }, "\u0434\u043E\u043F.\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B")),
                React.createElement("li", { onClick: closeMobileMenu, className: navItemClass('/about') },
                    React.createElement(Link, { to: "/about" }, "\u0410\u0412\u0422\u041E\u0420\u042B/\u041E \u041F\u0420\u041E\u0415\u041A\u0422\u0415"))))));
};
