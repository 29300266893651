var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import playBtn from '@assets/img/vector/other/play_button.svg';
import classnames from 'classnames';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import movieBorder from '../helpers/movieBorder';
import playerBorders from '../helpers/svgPlayerBorders';
export var VideoPoster = function (_a) {
    var src = _a.src, videoIntro = _a.videoIntro, videoPoster = _a.videoPoster, _b = _a.allowVideosPlay, allowVideosPlay = _b === void 0 ? true : _b, _c = _a.moviePage, moviePage = _c === void 0 ? false : _c;
    var _d = __read(React.useState(false), 2), hideOverlay = _d[0], setHideOverlay = _d[1];
    var _e = __read(React.useState('https://www.youtube.com/embed/${src}?autoplay=0'), 2), videoUrl = _e[0], setVideoUrl = _e[1];
    var _f = __read(React.useState(), 2), border = _f[0], setBorder = _f[1];
    React.useEffect(function () {
        var keys = Object.keys(playerBorders);
        setBorder(playerBorders[keys[(keys.length * Math.random()) << 0]]);
    }, []);
    React.useEffect(function () {
        setVideoUrl("https://www.youtube.com/embed/" + src + "?autoplay=0");
        setHideOverlay(false);
    }, [allowVideosPlay]);
    var onClickOverlay = React.useCallback(function () {
        setVideoUrl("https://www.youtube.com/embed/" + src + "?autoplay=1");
        setHideOverlay(true);
    }, []);
    var classes = "carousel__video-overlay" + (hideOverlay ? ' display-none' : '');
    var wrapperClasses = "carousel__video-wrapper" + (moviePage ? ' carousel__video-wrapper--movie' : '');
    return (React.createElement("div", { className: wrapperClasses, style: { backgroundImage: "url(" + movieBorder + ")" } },
        React.createElement("span", { onClick: onClickOverlay, className: classes },
            React.createElement(LazyLoadImage, { alt: videoPoster, src: videoPoster }),
            React.createElement("span", { className: "carousel__video-play", dangerouslySetInnerHTML: { __html: playBtn } }),
            !moviePage && (React.createElement("span", { className: "carousel__video-border", dangerouslySetInnerHTML: { __html: border || playerBorders.playerBorder01 } })),
            React.createElement("span", { className: "carousel__video-intro" }, videoIntro)),
        React.createElement("div", { className: "iframe-wrapper" },
            React.createElement("iframe", { className: classnames({ invisible: !hideOverlay }), width: "560", height: "315", src: videoUrl, title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))));
};
