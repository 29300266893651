var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import additionalContentData from '@assets/json/additionalContent.json';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useLocation } from 'react-router-dom';
import packageJson from './../../package.json';
import { Footer } from './Footer';
import { Header } from './header';
import { About } from './pages/about';
import { AdditionalContent } from './pages/additionalContent';
import { AdditionalContentPiece } from './pages/additionalContentPiece';
import { Alphabet } from './pages/alphabet';
import { Heroes } from './pages/heroes';
import { Home } from './pages/home';
import { Movie } from './pages/movie';
export var PageRouter = function () {
    var _a = __read(React.useState(''), 2), bgClass = _a[0], setBgClass = _a[1];
    var location = useLocation();
    useEffect(function () {
        if (location.pathname === '/sakharovo') {
            setBgClass('bg--movie');
        }
        else {
            setBgClass('');
        }
    }, [location]);
    useEffect(function () {
        console.log("Prison Alphabet version " + packageJson.version);
    }, []);
    return (React.createElement("div", { className: bgClass },
        React.createElement(Helmet, null,
            React.createElement("meta", { name: "viewport", content: "width=device-width,initial-scale=1" }),
            React.createElement("meta", { name: "description", content: "\u0422\u044E\u0440\u044C\u043C\u0430 \u043E\u0442 \u0410 \u0434\u043E \u042F. \u0427\u0443\u0436\u043E\u0439 \u043E\u043F\u044B\u0442 \u043C\u043E\u0436\u0435\u0442 \u0441\u0442\u0430\u0442\u044C \u0442\u0432\u043E\u0438\u043C. 40 \u0438\u043D\u0442\u0435\u0440\u0432\u044C\u044E. 300 \u0447\u0430\u0441\u043E\u0432 \u0432\u0438\u0434\u0435\u043E\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u043E\u0432. 256 \u043B\u0435\u0442 \u043B\u0438\u0448\u0435\u043D\u0438\u044F \u0441\u0432\u043E\u0431\u043E\u0434\u044B \u0441\u0443\u043C\u043C\u0430\u0440\u043D\u043E. \u0412 12 \u0441\u0435\u0440\u0438\u044F\u0445 \u043F\u043E 25 \u043C\u0438\u043D\u0443\u0442." }),
            React.createElement("title", null, "\u0410\u0437\u0431\u0443\u043A\u0430 \u0422\u044E\u0440\u044C\u043C\u044B"),
            React.createElement("link", { href: "https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap", rel: "stylesheet" }),
            React.createElement("link", { rel: "stylesheet", href: "https://use.typekit.net/ofs4cex.css" }),
            React.createElement("link", { rel: "icon", type: "image/svg+xml", href: "/assets/img/vector/other/favicon.svg" })),
        React.createElement(Header, null),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/" },
                React.createElement(Home, null)),
            React.createElement(Route, { path: "/sakharovo" },
                React.createElement(Movie, null)),
            React.createElement(Route, { path: "/heroes" },
                React.createElement(Heroes, null)),
            React.createElement(Route, { path: "/alphabet" },
                React.createElement(Alphabet, null)),
            React.createElement(Route, { path: "/additional-content" },
                React.createElement(AdditionalContent, null)),
            React.createElement(Route, { path: "/about" },
                React.createElement(About, null)),
            additionalContentData &&
                additionalContentData.map(function (d, i) { return (React.createElement(Route, { path: d.url, key: i },
                    React.createElement(AdditionalContentPiece, { introImg: d.introImg || '', title: d.title || '', intro: d.intro || [], questions: d.content || [], url: d.url || '' }))); })),
        React.createElement(Footer, null)));
};
