import animationHeaderAddMaterials from '@assets/img/vector/other/animation_header_add_materials.gif';
import additionalContentAll from '@assets/json/additionalContentAll.json';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { getOffset, prepareArrForRows } from '../../helpers/helpers';
import { AdditionalContentItem } from '../AdditionalContentItem';
import { PageTitle } from '../PageTitle';
import { ShareSocial } from '../ShareSocial';
export var AdditionalContent = function () {
    return (React.createElement(Grid, { fluid: true, className: "layout" },
        React.createElement(Helmet, null,
            React.createElement("link", { href: "https://zona.currenttime.tv/additional-content", rel: "canonical" })),
        React.createElement("div", { className: "container-fluid__paddings" },
            React.createElement(Row, null,
                React.createElement(Col, { xsOffset: 4, xs: 8, mdOffset: 5, md: 6, className: "title__bg-image" },
                    React.createElement("img", { src: animationHeaderAddMaterials })),
                React.createElement(Col, { xs: 12, lg: 6, className: "title__main title__main--add-content" },
                    React.createElement(PageTitle, { small: true, firstTitleLine: "\u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0422\u0435\u043B\u044C\u043D\u044B\u0435", secondTitleLine: "\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B" })),
                React.createElement(Col, { xs: 12, mdOffset: 5, md: 7, lgOffset: 8, lg: 4, className: "title__share" },
                    React.createElement(ShareSocial, null))),
            React.createElement("div", null, prepareArrForRows(additionalContentAll.flat(), 4).map(function (row, index) {
                var offset = getOffset();
                return (React.createElement(Row, { className: "layout__row", key: index }, row.map(function (item, idx) {
                    return (React.createElement(Col, { xsOffset: 0, mdOffset: 0, lgOffset: offset[idx], xs: 12, lg: 2, md: 6, key: idx },
                        React.createElement(AdditionalContentItem, { src: item.src, link: item.link, text: item.text, title: item.title })));
                })));
            })))));
};
