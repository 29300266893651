var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import arrowLight from '../../assets/img/vector/other/arrow-light.svg';
import arrowLeftLight from '../../assets/img/vector/other/arrow_left-light.svg';
export var MovieButtonGroup = function (_a) {
    var next = _a.next, previous = _a.previous, goToSlide = _a.goToSlide, numberOfSlides = _a.numberOfSlides, rest = __rest(_a, ["next", "previous", "goToSlide", "numberOfSlides"]);
    var currentSlide = rest.carouselState.currentSlide;
    var clickArrowNext = function () {
        if (currentSlide + 1 !== numberOfSlides) {
            next();
        }
    };
    var clickArrowPrevious = React.useCallback(function () {
        previous();
    }, []);
    return (React.createElement("div", { className: "movie__arrows" },
        React.createElement("span", { className: currentSlide > 0 ? '' : 'arrow--disabled' },
            React.createElement("div", { onClick: clickArrowPrevious, className: "carousel__arrow carousel__arrow--left" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: arrowLeftLight } }))),
        React.createElement("span", { className: currentSlide + 1 !== numberOfSlides ? '' : 'arrow--disabled' },
            React.createElement("div", { onClick: clickArrowNext, className: "carousel__arrow carousel__arrow--right" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: arrowLight } })))));
};
