import titleBgGigSrc from '@assets/img/vector/other/animation_header_heroes.gif';
import alphabetData from '@assets/json/alphabet.json';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { AlphabetItem } from '../AlphabetItem';
import { PageTitle } from '../PageTitle';
import { ShareSocial } from '../ShareSocial';
export var Alphabet = function () {
    return (React.createElement(Grid, { fluid: true, className: "layout" },
        React.createElement(Helmet, null,
            React.createElement("link", { href: "https://zona.currenttime.tv/alphabet", rel: "canonical" })),
        React.createElement("div", { className: "container-fluid__paddings" },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6, className: "title__main" },
                    React.createElement(PageTitle, { secondTitleLine: "\u0410\u041B\u0424\u0410\u0412\u0418\u0422" })),
                React.createElement(Col, { xsOffset: 3, xs: 8, mdOffset: 3, md: 5, className: "title__bg-image" },
                    React.createElement("img", { src: titleBgGigSrc })),
                React.createElement(Col, { xs: 12, mdOffset: 5, md: 7, lgOffset: 8, lg: 4, className: "title__share" },
                    React.createElement(ShareSocial, null))),
            alphabetData &&
                alphabetData.map(function (_a, index) {
                    var src = _a.src, alphabetWords = _a.alphabetWords;
                    return (React.createElement(AlphabetItem, { key: src + "-" + index, alphabetWords: alphabetWords, src: src }));
                }))));
};
