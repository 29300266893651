var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import facebook from '../../assets/img/vector/other/facebook.svg';
// @ts-ignore
import facebookLight from '../../assets/img/vector/other/facebook-light.svg';
// @ts-ignore
import telegram from '../../assets/img/vector/other/telegram.svg';
// @ts-ignore
import telegramLight from '../../assets/img/vector/other/telegram-light.svg';
// @ts-ignore
import twitter from '../../assets/img/vector/other/twitter.svg';
// @ts-ignore
import twitterLight from '../../assets/img/vector/other/twitter-light.svg';
// @ts-ignore
import vkontakte from '../../assets/img/vector/other/vkontakte.svg';
// @ts-ignore
import vkontakteLight from '../../assets/img/vector/other/vkontakte-light.svg';
export var ShareSocial = function () {
    var _a = __read(useState(false), 2), lightTheme = _a[0], setLightTheme = _a[1];
    var location = useLocation();
    useEffect(function () {
        if (location.pathname === '/sakharovo') {
            setLightTheme(true);
        }
        else {
            setLightTheme(false);
        }
    }, [location.pathname]);
    return (React.createElement("div", { className: "share__item" },
        React.createElement("h3", { className: "share__item-share" }, "\u043F\u043E\u0434\u0435\u043B\u0438\u0442\u0435\u0441\u044C \u0432 \u0441\u043E\u0446\u0441\u0435\u0442\u044F\u0445"),
        React.createElement("div", { className: "share__item-links" },
            React.createElement("a", { href: "http://twitter.com/share?text=" + window.location.href, target: "_blank", rel: "noreferrer" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: lightTheme ? twitterLight : twitter } })),
            React.createElement("a", { href: "https://telegram.me/share/url?url=" + window.location.href, target: "_blank", rel: "noreferrer" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: lightTheme ? telegramLight : telegram } })),
            React.createElement("a", { href: "https://vk.com/share.php?url=" + window.location.href, target: "_blank", rel: "noreferrer" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: lightTheme ? vkontakteLight : vkontakte } })),
            React.createElement("a", { href: "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href, target: "_blank", rel: "noreferrer" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: lightTheme ? facebookLight : facebook } })))));
};
