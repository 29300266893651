var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// @ts-ignore
import arrow from '@assets/img/vector/other/arrow.svg';
import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { AdditionalContentItem } from './AdditionalContentItem';
import { AlphabetCarouselItem } from './AlphabetCarouselItem';
import { CarouselTitle } from './CarouselTitle';
import { HeroItemMainPage } from './HeroItemMainPage';
import { Modal } from './Modal';
var responsiveHeroes = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 90,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        infinite: false,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};
var responsiveAlphabet = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 90,
    },
    desktopSmall: {
        breakpoint: { max: 1500, min: 1024 },
        items: 4,
        partialVisibilityGutter: 20,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};
var responsiveAdditional = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 90,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
export var PageCarousel = function (_a) {
    var heroesData = _a.heroesData, visibleEpisode = _a.visibleEpisode, alphabetData = _a.alphabetData, additionalContentData = _a.additionalContentData;
    var _b = __read(React.useState(false), 2), showModal = _b[0], setShowModal = _b[1];
    var _c = __read(React.useState(''), 2), activeModal = _c[0], setActiveModal = _c[1];
    var handleShowModal = function (e) {
        setActiveModal(e.currentTarget.dataset.src || '');
        setShowModal(true);
    };
    var handleHideModal = function () {
        setActiveModal('');
        setShowModal(false);
    };
    return (React.createElement("div", null,
        heroesData.map(function (heroes, index) {
            if (index + 1 === visibleEpisode) {
                return (React.createElement("div", { key: index, className: "carousel__wrapper carousel__wrapper--movable" },
                    React.createElement(Carousel, { className: "carousel__heroes", 
                        // centerMode
                        partialVisible: true, arrows: false, responsive: responsiveHeroes, keyBoardControl: true, renderButtonGroupOutside: true, draggable: true, renderArrowsWhenDisabled: true, customButtonGroup: React.createElement(CarouselTitle, { arrows: true, episode: "" + visibleEpisode }, "\u0433\u0435\u0440\u043E\u0438") },
                        heroes.map(function (_a, index) {
                            var heroName = _a.heroName, heroText = _a.heroText, heroSrc = _a.heroSrc;
                            return (React.createElement(HeroItemMainPage, { key: heroName, setMargin: true, name: heroName, img: heroSrc, text: heroText }));
                        }),
                        React.createElement("span", { className: "layout__item layout__item--arrow" },
                            React.createElement(Link, { to: "/heroes" },
                                React.createElement("span", { dangerouslySetInnerHTML: { __html: arrow } })),
                            React.createElement("p", null, "\u0432\u0441\u0435 \u0433\u0435\u0440\u043E\u0438")))));
            }
            else {
                return null;
            }
        }),
        alphabetData.map(function (alphabetItem, index) {
            if (index + 1 === visibleEpisode) {
                return (React.createElement("div", { key: index, className: "carousel__wrapper carousel__wrapper--movable", style: { display: "" + (visibleEpisode === index + 1 ? 'flex' : 'none') } },
                    React.createElement(Carousel, { className: "carousel__alphabet", partialVisible: true, arrows: false, responsive: responsiveAlphabet, keyBoardControl: true, renderButtonGroupOutside: true, draggable: true, renderArrowsWhenDisabled: true, customButtonGroup: React.createElement(CarouselTitle, { arrows: true, episode: "" + visibleEpisode }, "\u0410\u041B\u0424\u0410\u0412\u0418\u0422") },
                        alphabetItem.map(function (item, idx) { return (React.createElement(AlphabetCarouselItem, { key: "alphabet-" + idx, item: item, handleShowModal: handleShowModal, clickable: !!item.meaning })); }),
                        React.createElement("span", { className: "layout__item layout__item--arrow" },
                            React.createElement(Link, { to: "/alphabet" },
                                React.createElement("span", { dangerouslySetInnerHTML: { __html: arrow } })),
                            React.createElement("p", null, "\u0432\u0435\u0441\u044C \u0430\u043B\u0444\u0430\u0432\u0438\u0442"))),
                    React.createElement(Modal, { show: showModal, activeModal: activeModal, handleClose: handleHideModal })));
            }
            else {
                return null;
            }
        }),
        additionalContentData.map(function (additionalContentItem, index) {
            if (index + 1 === visibleEpisode) {
                return (React.createElement("div", { key: index, className: "carousel__wrapper carousel__wrapper--movable", style: { display: "" + (visibleEpisode === index + 1 ? 'flex' : 'none') } },
                    React.createElement(Carousel, { className: "carousel__additional", arrows: false, responsive: responsiveAdditional, keyBoardControl: true, renderButtonGroupOutside: true, draggable: true, renderArrowsWhenDisabled: true, customButtonGroup: React.createElement(CarouselTitle, { marquee: true, episode: "" + visibleEpisode, toEdges: true }, "\u0434\u043E\u043F.\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B") }, additionalContentItem.map(function (_a, index) {
                        var title = _a.title, text = _a.text, src = _a.src, link = _a.link;
                        return (React.createElement(AdditionalContentItem, { key: index, src: src, title: title, text: text, link: link, mainPage: true }));
                    }))));
            }
            else {
                return null;
            }
        })));
};
