var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import borders from '../helpers/svgHeroBorders';
export var AlphabetCarouselItem = function (_a) {
    var _b;
    var item = _a.item, handleShowModal = _a.handleShowModal, _c = _a.clickable, clickable = _c === void 0 ? false : _c;
    var _d = __read(React.useState(), 2), border = _d[0], setBorder = _d[1];
    React.useEffect(function () {
        var keys = Object.keys(borders);
        setBorder(borders[keys[(keys.length * Math.random()) << 0]]);
    }, []);
    var onClick = function (e) {
        if (clickable) {
            handleShowModal(e);
        }
    };
    var classes = function (hasDefinition) {
        return "layout__item layout__item--clickable" + (!clickable || !hasDefinition ? ' disabled ' : '') + " " + (item.type === 'word' ? ' layout__item--word' : ' layout__item--letter');
    };
    return (React.createElement("span", { className: classes(!!((_b = item.meaning) === null || _b === void 0 ? void 0 : _b.length)), onClick: onClick, "data-src": item.meaning },
        React.createElement("div", { className: "layout__item-img-wrapper" },
            clickable && (React.createElement("span", { className: "layout__item-bg", style: {
                    backgroundImage: "url(" + border + ")",
                    backgroundRepeat: 'no-repeat',
                } })),
            React.createElement(LazyLoadImage, { alt: item.link, src: item.link, className: "layout__item-img" }))));
};
